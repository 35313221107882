<script setup>
import { inject, reactive, ref, watch } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import { deepCopy } from '@/utils/common'
import { REQUISITES_TYPES } from '@/models/P2P/RequisitesModel'

const emit = defineEmits(['save', 'delete'])

const saved = ref(false)
const requisitesByType = inject('requisitesByType')

const fields = reactive({
  companyName: '',
  companyAddress: '',
  accountNumber: '',
  holderName: '',
  bankName: '',
  bankLocation: '',
  bankAddress: '',
  accountType: '',
  swiftCode: '',
  routingNumber: '',
  bankCode: ''
})
if (requisitesByType.value[REQUISITES_TYPES.FOREIGN_ACCOUNT].length) {
  const item = requisitesByType.value[REQUISITES_TYPES.FOREIGN_ACCOUNT][0]
  Object.keys(item).forEach(prop => {
    fields[prop] = item[prop]
  })
  saved.value = true
}

const requiredFields = reactive({
  companyName: '',
  companyAddress: '',
  accountNumber: '',
  holderName: '',
  bankName: '',
  bankLocation: '',
  bankAddress: '',
  accountType: '',
  swiftCode: '',
  routingNumber: '',
  bankCode: ''
})

const errors = reactive({
  companyName: null,
  companyAddress: null,
  accountNumber: null,
  holderName: null,
  bankName: null,
  bankLocation: null,
  bankAddress: null,
  accountType: null,
  swiftCode: null,
  routingNumber: null,
  bankCode: null
})

const validate = () => {
  if (!validateRequired()) return false
  return true
}

const validateRequired = () => {
  Object.keys(requiredFields).forEach(prop => {
    if (fields[prop] === '') errors[prop] = true
  })
  if (Object.values(errors).some(Boolean)) {
    notify({
      title: window.$t('error_title'),
      text: $t('fields_is_required'),
      type: 'error'
    })
    return false
  }
  return true
}

const save = () => {
  if (saved.value || !validate()) return
  emit('save', deepCopy(fields))
  saved.value = true
}

const deleteData = () => {
  if (!saved.value) return
  Object.keys(fields).forEach(field => { fields[field] = '' })
  emit('delete')
}

watch(fields, (to) => {
  if (saved.value) saved.value = false
})

const resetErrors = () => { Object.keys(errors).forEach(p => { errors[p] = null })}
</script>

<template lang="pug">
.ce-foreign-account
  .ce-foreign-account_fields
    .ce-foreign-account_fields-company.ce-shadow-block
      .label Информация о компании
      .fields
        ce-text-field(
          v-model="fields.companyName"
          :label="'Название'"
          :error="errors.companyName"
          :required="true"
          @update:model-value="errors.companyName = ''"
        )
        ce-text-field(
          v-model="fields.companyAddress"
          :label="'Адрес'"
          :error="errors.companyAddress"
          :required="true"
          @update:model-value="errors.companyAddress = ''"
        )
    .ce-foreign-account_fields-bank.ce-shadow-block
      .label Информация о банке
      .fields
        ce-text-field(
          v-model="fields.accountNumber"
          :label="'Номер счёта'"
          :error="errors.accountNumber"
          :required="true"
          @update:model-value="errors.accountNumber = ''"
        )
        ce-text-field(
          v-model="fields.holderName"
          :label="'Имя держателя счёта'"
          :error="errors.holderName"
          :required="true"
          @update:model-value="errors.holderName = ''"
        )
        ce-text-field(
          v-model="fields.bankName"
          :label="'Название банка'"
          :error="errors.bankName"
          :required="true"
          @update:model-value="errors.bankName = ''"
        )
        ce-text-field(
          v-model="fields.bankLocation"
          :label="'Страна банка'"
          :error="errors.bankLocation"
          :required="true"
          @update:model-value="errors.bankLocation = ''"
        )
        ce-text-field(
          v-model="fields.bankAddress"
          :label="'Адрес банка'"
          :error="errors.bankAddress"
          :required="true"
          @update:model-value="errors.bankAddress = ''"
        )
        ce-text-field(
          v-model="fields.accountType"
          :label="'Тип счета'"
          :error="errors.accountType"
          :required="true"
          @update:model-value="errors.accountType = ''"
        )
        ce-text-field(
          v-model="fields.swiftCode"
          :label="'SWIFT/БИК'"
          :error="errors.swiftCode"
          :required="true"
          @update:model-value="errors.swiftCode = ''"
        )
        ce-text-field(
          v-model="fields.routingNumber"
          :label="'Номер маршрута'"
          :error="errors.routingNumber"
          :required="true"
          @update:model-value="errors.routingNumber = ''"
        )
        ce-text-field(
          v-model="fields.bankCode"
          :label="'Код банка'"
          :error="errors.bankCode"
          :required="true"
          @update:model-value="errors.bankCode = ''"
        )
  .ce-foreign-account_actions
    button.ce-button.ce-button--yellow(
      :class="{ saved }"
      @click="save"
    ) Сохранить
    button.ce-button.ce-button--brand-red2(
      :class="{ disabled: !saved }"
      @click="deleteData"
    ) Удалить
</template>

<style scoped lang="scss">
.ce-foreign-account {
  max-height: 70vh;
  &_fields {
    @include forDesktop_v2 {
      display: grid;
      grid-gap: 1rem;
      min-width: 800px;
    }
    .label {
      font-size: 1rem;
      font-family: Geometria, sans-serif;
      font-weight: 500;
      margin-bottom: 1.25rem;
    }
    .fields {
      display: grid;
      grid-gap: 0.5rem;
      @include forDesktop_v2 {
      }
    }
    &-bank {
      .fields {
        @include forDesktop_v2 {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
  &_actions {
    margin: 1rem 0 0 0;
    display: grid;
    grid-gap: 0.5rem;
    @include forDesktop_v2 {
      display: flex;
      gap: 0.5rem;
      justify-content: flex-end;
    }
    button {
      @include forDesktop_v2 {
        width: 30%;
      }
      &.saved {
        background: $Brand_Light_Grey!important;
        border: 1px solid $Brand_Light_Grey!important;
        &:hover {

        }
      }
    }
  }
}
</style>
